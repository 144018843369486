import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Margin} from '../entities/margin';
import * as FileSaver from 'file-saver';
import {ApiService} from './apiService';
import {HttpClient} from '@angular/common/http';
import {Currency} from '../entities/currency';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  getCurrencies(type: 'source' | 'destination'): Observable<Currency[]> {
    return this.http.get<Currency[]>(`${this.baseUrl}currencies/${type}`);
  }

  saveCurrency(type: 'source' | 'destination', currency: Currency): Observable<boolean> {
    return this.http.post<boolean>(`${this.baseUrl}currencies/${type}`, currency);
  }

  deleteCurrency(type: 'source' | 'destination', alpha3: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}currencies/${type}/${alpha3}`);
  }

  downloadReport(type?: 'source' | 'destination') {
    this.http.get(this.baseUrl + `currencies/report${type ? '/' + type : ''}`, {responseType: 'text'}).subscribe(response => {
      const blob = new Blob([response], {type: 'text/csv;charset=utf-8'});
      FileSaver.saveAs(blob, `tvx-vbmc-${type ? type + '-' : ''}currencies-snapshot-${Date.now().toString()}.csv`);
    });
  }
}
