import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './components/home/home.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {SidenavContentComponent} from './components/sidenav-content/sidenav-content.component';
import {CurrenciesListComponent} from './components/currencies-list/currencies-list.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TotalCardComponent} from './components/total-card/total-card.component';
import {RatesCardComponent} from './components/rates-card/rates-card.component';
import {MarginsCardComponent} from './components/margins-card/margins-card.component';
import {MatInputModule} from '@angular/material/input';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {CurrencyEditDialogComponent} from './components/currency-edit-dialog/currency-edit-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MarginEditDialogComponent} from './components/margin-edit-dialog/margin-edit-dialog.component';
import {BulkAddMarginDialogComponent} from './components/bulk-add-margin-dialog/bulk-add-margin-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CookieService} from 'ngx-cookie-service';
import {AuthComponent} from './components/auth/auth.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SigninComponent} from './components/signin/signin.component';
import {RecoveryChallengeComponent} from './components/recovery-code/recovery-challenge.component';
import {SignupComponent} from './components/signup/signup.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {RecoveryComponent} from './components/recovery/recovery.component';
import {AuthenticationInterceptor} from './services/authentication-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    ToolbarComponent,
    SidenavContentComponent,
    CurrenciesListComponent,
    TotalCardComponent,
    RatesCardComponent,
    MarginsCardComponent,
    CurrencyEditDialogComponent,
    MarginEditDialogComponent,
    BulkAddMarginDialogComponent,
    AuthComponent,
    SigninComponent,
    SignupComponent,
    RecoveryComponent,
    RecoveryChallengeComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    HttpClientModule,
    MatMenuModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSnackBarModule
  ],
  providers: [
    HttpClient,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
