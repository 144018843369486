<div class="header">

</div>
<mat-action-list>
  <button class="link" mat-list-item routerLink="/currencies" routerLinkActive="active">
    <mat-icon>euro</mat-icon>
    <span>Currencies</span>
  </button>
  <button class="link" mat-list-item routerLink="/margins" routerLinkActive="active">
    <mat-icon>local_atm</mat-icon>
    <span>Margins</span>
  </button>
</mat-action-list>
