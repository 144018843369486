<h2>Sign In</h2>
<form [formGroup]="signinForm" (ngSubmit)="onSubmit()" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput placeholder="Email" formControlName="username" autocomplete="on">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input type="password" matInput placeholder="Password" formControlName="password" autocomplete="on">
  </mat-form-field>
  <div class="footer" fxLayoutAlign="space-between center">
    <a [routerLink]="['/auth/recovery']">Forgot Password</a>
    <button type="submit" mat-raised-button color="primary" [disabled]="signinForm.invalid || showProgressBar">Sign In</button>
  </div>
</form>
<div class="progressbar" *ngIf="showProgressBar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
