import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

export class ApiService {
  protected readonly baseUrl;

  constructor() {
    if (environment.apiBase.includes('localhost')) {
      console.warn('Using local backend. Dont forget to use hosted backend instead');
    }

    this.baseUrl = environment.apiBase;

    if (!this.baseUrl) {
      console.error('[AFF]: No base url set for backend');
    }
  }
}
