<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <span>Bulk Add Margins</span>
</mat-toolbar>
<mat-progress-bar mode="indeterminate" *ngIf="isBusy" color="accent"></mat-progress-bar>
<div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
  <input hidden type="file" #uploader (change)="uploadFile($event)"/>
  <div class="placeholder" fxLayout="column" fxLayoutAlign="center center" *ngIf="!marginsInFile && !errors" [ngClass]="{'fileOverStyle':fileOver}">
    <mat-icon class="icon">description</mat-icon>
    <span class="text">
      Drop your data here, Or alternatively,
      <button mat-button (click)="uploader.click()" color="primary">Browse</button>
    </span>
  </div>
  <div class="diff" fxLayout="row" fxLayoutGap="8px" *ngIf="marginsInFile" fxLayoutAlign="center flex-start">
    <mat-card fxFlex="250px" class="changes-card">
      <mat-card-header>
        <mat-card-title>Changes</mat-card-title>
      </mat-card-header>
      <mat-card-content [ngClass]="{'merge': merge}">
        <div class="currency-pair" *ngFor="let margin of diffMargins" fxLayout="row" fxLayoutAlign="flex-start center"
             style="padding: 8px 0" [ngClass]="[margin.type]">
          <b>{{margin.sourceCurrency}} </b>
          <mat-icon>chevron_right</mat-icon>
          <b>{{margin.destinationCurrency}}</b>
          <b class="margin" style="margin-left: 16px"> = {{margin.margin}}%</b>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="right" fxLayout="column" fxLayoutGap="8px" fxFlex="100%">
      <mat-card *ngIf="errors?.length > 0" class="duplicate-entry-card">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title class="title">Validation Errors</mat-card-title>
          <button mat-icon-button (click)="errorsActive = !errorsActive">
            <mat-icon>{{errorsActive ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <p class="description">The following validation errors occurred when reading the uploaded file:</p>
          <div class="duplicates" [ngClass]="{'active': errorsActive}">
            <ul>
              <li class="error" *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="duplicateMargins?.length > 0" class="duplicate-entry-card">
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title class="title">Duplicate entries found</mat-card-title>
          <button mat-icon-button (click)="duplicatesActive = !duplicatesActive">
            <mat-icon>{{duplicatesActive ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <p class="description">{{duplicateMargins.length}}
            margin {{duplicateMargins.length > 1 ? 'entries' : 'entry'}} {{duplicateMargins.length > 1 ? 'have' : 'has'}}
            been defined multiple times. Only the first for a given pair will be loaded. Expand for details.</p>
          <div class="duplicates" [ngClass]="{'active': duplicatesActive}">
            <div class="currency-pair" *ngFor="let margin of duplicateMargins" fxLayout="row"
                 fxLayoutAlign="flex-start center"
                 style="padding: 0">
              <b>{{margin.sourceCurrency}} </b>
              <mat-icon>chevron_right</mat-icon>
              <b>{{margin.destinationCurrency}}</b>
              <b class="margin" style="margin-left: 16px"> = {{margin.margin}}%</b>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <mat-card-title>Stats</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
          <div class="operation added" fxLayout="column" fxLayoutAlign="center center">
            <h3>Added</h3>
            <span class="count">{{addedCount || 0}}</span>
          </div>
          <div class="operation updated" fxLayout="column" fxLayoutAlign="center center">
            <h3>Updated</h3>
            <span class="count">{{updatedCount || 0}}</span>
          </div>
          <div class="operation deleted" fxLayout="column" fxLayoutAlign="center center">
            <h3>Deleted</h3>
            <span class="count">{{merge ? 0 : deletedCount || 0}}</span>
          </div>
          <div class="operation unchanged" fxLayout="column" fxLayoutAlign="center center">
            <h3>Unchanged</h3>
            <span class="count">{{unchangedCount || 0}}</span>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card *ngIf="isBusy || failedUpdates > 0">
        <mat-card-header>
          <mat-card-title>Progress</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
          <div class="operation added" fxLayout="column" fxLayoutAlign="center center">
            <h3>Total Changes Required</h3>
            <span class="count">{{totalToUpdate || 0}}</span>
          </div>
          <div class="operation added" fxLayout="column" fxLayoutAlign="center center">
            <h3>Completed</h3>
            <span class="count">{{successfulUpdates || 0}}</span>
          </div>
          <div class="operation updated" fxLayout="column" fxLayoutAlign="center center">
            <h3>Failed</h3>
            <span class="count">{{failedUpdates || 0}}</span>
          </div>
        </mat-card-content>
      </mat-card>
      <div *ngIf="!isBusy && failedUpdates > 0" style="color: red;">
        Some margins failed to update. If you want to retry the above failed changes, exit this dialog and upload the same margins file again.
      </div>
    </div>
  </div>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="flex-start center">
  <button mat-flat-button (click)="uploader.click()" color="primary">Select File</button>
  <mat-slide-toggle style="margin-left: 16px" [checked]="merge"
                    (change)="merge = $event.source.checked">Merge
  </mat-slide-toggle>
  <button mat-button (click)="cancel()" style="margin-left: auto">Cancel</button>
  <button mat-flat-button (click)="saveMargins()" color="primary" style="margin-left: 8px"
          [disabled]="cancelled || failedUpdates !== 0 || isBusy || invalid || (this.marginsToUpdate.length === 0 && this.marginsToDelete.length === 0)">{{merge ? 'Merge Margins' : 'Replace Margins'}}
  </button>
</div>
