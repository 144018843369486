<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="refreshRates()" [disabled]="isBusy">
    <mat-icon>refresh</mat-icon>
    <span>Refresh</span>
  </button>
  <button mat-menu-item (click)="downloadReport()">
    <mat-icon>get_app</mat-icon>
    <span>Download</span>
  </button>
</mat-menu>
<mat-card>
  <mat-card-header fxLayout="row">
    <div mat-card-avatar class="header-image"></div>
    <mat-card-title>Mastercard Rates</mat-card-title>
    <mat-card-subtitle>USD Based</mat-card-subtitle>
    <button mat-icon-button [matMenuTriggerFor]="appMenu" style="margin-left: auto">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="rates" class="mat-elevation-z8">

      <!-- Name Column -->
<!--      <ng-container matColumnDef="country">-->
<!--        <th mat-header-cell *matHeaderCellDef>Country</th>-->
<!--        <td mat-cell *matCellDef="let element">-->
<!--          <div class="currency-pair" fxLayout="row" fxLayoutAlign="flex-start center">-->
<!--            <img src="https://www.countryflags.io/{{element.destinationCountryAlpha2}}/flat/16.png">-->
<!--            <b>{{element.destinationCountryAlpha3}} </b>-->
<!--          </div>-->
<!--        </td>-->
<!--      </ng-container>-->

      <!-- Position Column -->
      <ng-container matColumnDef="destinationCurrency">
        <th mat-header-cell *matHeaderCellDef>Currency</th>
        <td mat-cell *matCellDef="let element"><b>{{element.destinationCurrency}}</b></td>
      </ng-container>

      <!-- Menu Button Column -->
      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>Rate</th>
        <td mat-cell *matCellDef="let element"><b>{{element.rate}}</b></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-progress-bar mode="indeterminate" *ngIf="isBusy" color="accent"></mat-progress-bar>
</mat-card>
