import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-total-card',
  templateUrl: './total-card.component.html',
  styleUrls: ['./total-card.component.scss']
})
export class TotalCardComponent implements OnInit {
  @Input() title: string;
  @Input() count: number;
  @Input() color: 'primary' | 'accent';

  constructor() {
  }

  ngOnInit(): void {
  }

}
