import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum ToastType {
  SUCCESS,
  INFO,
  WARNING,
  ERROR
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {
  }

  queue(state: ToastType, message: string, ...actions: (() => void)[]) {
    this.snackBar.open(message, null, {
      duration: 5000
    });
  }
}
