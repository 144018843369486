import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {

  recoveryForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
  });

  showProgressBar = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    this.showProgressBar = true;
    this.authService.recovery(this.recoveryForm.value.username, {
      onSuccess: (result) => {
        this.showProgressBar = false;
      },
      onFailure: (err) => {
        this.showProgressBar = false;
      },
    });
  }
}
