import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  // mode: 'signin' | 'signup' | 'password-reset' | 'recovery' | 'recovery-challenge' = 'signin';

  constructor() { }

  ngOnInit() {

  }

}
