<h3>Reset Password</h3>
<form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>New Password</mat-label>
    <input type="password" matInput placeholder="New Password" formControlName="newPassword" autocomplete="off">
  </mat-form-field>
  <!-- <div *ngIf="this.passwordResetForm.controls.newPassword.errors">
    <div *ngIf="this.passwordResetForm.controls.newPassword.errors.required">Password is required</div>
    <div *ngIf="this.passwordResetForm.controls.newPassword.errors.minlength">Password must be at least 6 characters</div>
    <div *ngIf="this.passwordResetForm.controls.newPassword.errors.pattern">Password does not meet the criteria listed below</div>
  </div> -->
  <mat-form-field appearance="outline">
    <mat-label>Repeat Password</mat-label>
    <input type="password" matInput placeholder="Repeat Password" formControlName="newPasswordConfirmation" autocomplete="off">
  </mat-form-field>
  <b>Note</b>
  <p>Your new password should have a minimum length of 8 characters and contain the following:</p>
  <ul>
    <li>At least 1 uppercase character</li>
    <li>At least 1 lowercase character</li>
    <li>At least 1 special character</li>
    <li>At least 1 number</li>
  </ul>
  <div class="footer" fxLayoutAlign="flex-end">
    <button type="submit" mat-raised-button color="primary" [disabled]="passwordResetForm.invalid || showProgressBar">Update Password</button>
  </div>
</form>
<div class="progressbar" *ngIf="showProgressBar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
