<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="refreshCurrencies()" [disabled]="isBusy">
    <mat-icon>refresh</mat-icon>
    <span>Refresh</span>
  </button>
  <button mat-menu-item (click)="downloadReport()">
    <mat-icon>get_app</mat-icon>
    <span>Download</span>
  </button>
</mat-menu>
<mat-menu #itemMenu="matMenu" (closed)="selectedCurrency = undefined">
  <button mat-menu-item (click)="editSelectedCurrency()">
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)="deleteSelectedCurrency(selectedCurrency.alpha3)">
    <mat-icon class="warn">delete</mat-icon>
    <span class="warn">Delete</span>
  </button>
</mat-menu>
<mat-card>
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
    <button mat-icon-button style="margin-left: auto" (click)="addCurrency()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button [mat-menu-trigger-for]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="currencies" class="mat-elevation-z8">


      <!-- Currency Code Column -->
      <ng-container matColumnDef="alpha3">
        <th mat-header-cell *matHeaderCellDef>Alpha</th>
        <td mat-cell *matCellDef="let element"> <b>{{element.alpha3}}</b> </td>
      </ng-container>

      <!-- Currency Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Menu Button Column -->
      <!-- Menu Button Column -->
      <ng-container matColumnDef="menu" stickyEnd>
        <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
        <td mat-cell *matCellDef="let element">
          <button class="item-menu-button" [ngClass]="{active: selectedCurrency == element}" mat-icon-button (click)="setSelectedEntry(element)" [mat-menu-trigger-for]="itemMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-progress-bar mode="indeterminate" *ngIf="isBusy" color="accent"></mat-progress-bar>
</mat-card>
