import {ComponentFactoryResolver, Injectable} from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {SnackbarService, ToastType} from './snackbar.service';
import * as jwt_decode from 'jwt-decode';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../environments/environment';

export class User {
  firstName?: string;
  lastName?: string;
  email?: string;

  constructor(firstName: string, lastName: string, email: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Auth status property
  private _authStatus = false;
  private authStatusSubject: Subject<boolean> = new Subject<boolean>();
  public readonly authStatus: Observable<boolean> = this.authStatusSubject.asObservable();

  private userPool = new CognitoUserPool(environment.poolData);
  private cognitoUser;
  // private auth: CognitoUserSession;
  private auth: string;
  private GUIName: string;

  private resetCode: string;

  constructor(private router: Router, private toastService: SnackbarService,
              private http: HttpClient, private cookieService: CookieService) {

    this.cognitoUser = this.userPool.getCurrentUser();

    // Get existing auth cookie
    const authToken = this.cookieService.get('Authorization');
    if (authToken != null && authToken !== ''){

      const decodedToken = this.decodeAccessToken(authToken);

      // Check that cookie is valid and auth is not over an hour old
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);

      if (decodedToken.token_use === 'id' &&
          decodedToken.aud === environment.poolData.ClientId &&
          decodedToken.iss.endsWith(environment.poolData.UserPoolId) &&
          (currentTimeInSeconds - decodedToken.auth_time) < (60 * 60)){
        this.auth = authToken;
        this._authStatus = true;
        this.GUIName = decodedToken.email;
      }else{
        this.signout();
      }
    }
  }

  public setResetCode(code){
    this.resetCode = code;
  }

  public getResetCode(): string{
    return this.resetCode;
  }

  public getToken(): string {
    return this.auth;
  }

  decodeAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  public signup(user: string, password: string, email: string) {
    const emailData = {
      Name: 'email',
      Value: email
    };
    const emailAtt = [new CognitoUserAttribute(emailData)];

    this.userPool.signUp(user, password, emailAtt, null, ((err, result) => {
      if (err) {
        console.error(err);
      }
    }));
  }

  public confirm(username: string, code: string) {
    const userData = {
      Username: username,
      Pool: this.userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.error('There was an error -> ', err);
      }
    });
  }

  public signin(username: string, password: string, uiCallback: any) {
    this.resetCode = '';

    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    this.cognitoUser = new CognitoUser(userData);

    return this.cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        uiCallback.onSuccess(result);
        this.auth = result.getIdToken().getJwtToken();

        this.cookieService.set('Authorization', this.auth, 1);

        this.GUIName = result.getIdToken().payload.email;

        this._authStatus = true;
        this.router.navigate(['/']);
      },
      onFailure: (err) => {
        uiCallback.onFailure(err);
        this._authStatus = false;
        this.toastService.queue(ToastType.ERROR, 'There was a problem during signin. Please try again');
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        uiCallback.onSuccess(null);
        // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user.
        // Required attributes according to schema, which don’t have any values yet, will have blank values.
        // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.

        // Get these details and call
        // newPassword: password that user has given
        // attributesData: object with key as attribute name and value that the user has given.
        this.router.navigateByUrl('/auth/reset');
      }
    });
  }

  public setNewPassword(newPassword, uiCallback: any) {
    this.cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (session) => {
        uiCallback.onSuccess(session);
        this.toastService.queue(ToastType.SUCCESS, 'Password successfully reset');
        this.resetCode = '';
        this.router.navigate(['/']);
      },
      onFailure: (err) => {
        uiCallback.onFailure(err);
        this.resetCode = '';
        this.toastService.queue(ToastType.SUCCESS, 'There was a problem resetting your password. Please try again');
      }
    });
  }

  public resetPassword(newPassword, uiCallback) {
    this.cognitoUser.confirmPassword(this.resetCode, newPassword, {
      onSuccess: (session) => {
        uiCallback.onSuccess(session);
        this.toastService.queue(ToastType.SUCCESS, 'Password successfully reset');
        this.resetCode = '';
        this.router.navigate(['/']);
      },
      onFailure: (err) => {
        uiCallback.onFailure(err);
        this.resetCode = '';
        if (err?.code === 'CodeMismatchException'){
          this.toastService.queue(ToastType.SUCCESS, 'Invalid verification code. Please try again.');
          this.router.navigateByUrl('/auth/recovery-code');
        }else{
          this.toastService.queue(ToastType.SUCCESS, 'There was a problem resetting your password. Please try again.');
        }
      }
    });
  }

  public signout() {
    this.resetCode = '';

    if (this.userPool.getCurrentUser() != null){
      this.userPool.getCurrentUser().signOut();
    }

    this.toastService.queue(ToastType.SUCCESS, 'You are now signed out');
    this._authStatus = false;

    this.cookieService.delete('Authorization');

    this.router.navigate(['auth']);
  }

  public getAuthStatus(): boolean {
    return this._authStatus;
  }

  public getCognitoUser(): CognitoUser{
    return this.cognitoUser;
  }

  recovery(username: string, uiCallback: any) {
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    this.cognitoUser = new CognitoUser(userData);

    this.cognitoUser.forgotPassword({
      onSuccess: (data: any) => {
        uiCallback.onSuccess(data);
        this.toastService.queue(ToastType.SUCCESS, `Recovery email sent to ${username}.`);
      },
      onFailure: (err: any) => {
        uiCallback.onFailure(err);
        if (err?.code === 'UserNotFoundException'){
          this.router.navigateByUrl('/auth/recovery-code');
        }else{
          this.toastService.queue(ToastType.ERROR, `Failed to send recovery email. Please try again later.`);
        }
      },
      inputVerificationCode: (data: any) => {
        uiCallback.onSuccess(data);
        this.router.navigateByUrl('/auth/recovery-code');
      }
    });
  }

  public getUserName(): string {
    return this.GUIName;
  }
}
