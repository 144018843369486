import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as FileSaver from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './apiService';
import {Margin} from '../entities/margin';
import { SnackbarService, ToastType } from './snackbar.service';
import { MarginRequest } from '../entities/margin-request';

@Injectable({
  providedIn: 'root'
})
export class MarginService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  uploadMargins(marginRequest: MarginRequest): Observable<boolean> {

    return this.http.post<boolean>(this.baseUrl + 'margins', marginRequest, {
      reportProgress: true
    });
  }

  getMargins(): Observable<Margin[]> {
    return this.http.get<Margin[]>(this.baseUrl + 'margins');
  }

  deleteMargin(marginId: string) {
    return this.http.delete<boolean>(`${this.baseUrl}margins/${marginId}`);
  }

  downloadReport() {
    this.http.get(this.baseUrl + 'margins/report', {responseType: 'text'}).subscribe(response => {
      const blob = new Blob([response], {type: 'text/csv;charset=utf-8'});
      FileSaver.saveAs(blob, `tvx-vbmc-margins-snapshot-${Date.now().toString()}.csv`);
    });
  }

  saveMargin(margin: Margin) {
    return this.http.patch<boolean>(`${this.baseUrl}margins/${margin.id}`, margin);
  }
}
