<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <span>{{(currencySnapshot ? 'Edit' : 'Add') + ' Currency'}}</span>
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="content">
  <form [formGroup]="currencyForm" fxLayout="column" fxLayoutGap="16px">
    <div class="row" fxLayout="row" fxLayoutGap="16px">
      <mat-form-field appearance="fill" fxFlex="50%">
        <mat-label>Alpha 3</mat-label>
        <input matInput placeholder="Alpha 3" formControlName="alpha3" maxlength="3">
        <mat-hint>eg. AUD</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="50%">
        <mat-label>Decimals</mat-label>
        <input matInput placeholder="Decimals" formControlName="decimals">
        <mat-hint>eg. 2</mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput placeholder="Name" formControlName="name">
      <mat-hint>eg. Australian Dollars</mat-hint>
    </mat-form-field>
    <!--    <mat-form-field appearance="fill" >-->
    <!--      <mat-label>Alpha 3</mat-label>-->
    <!--      <mat-slide-toggle formControlName="enabled">Enable</mat-slide-toggle>-->
    <!--      <mat-hint>eg. USD</mat-hint>-->
    <!--    </mat-form-field>-->
  </form>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="flex-start center">
  <button mat-button (click)="deleteCurrency()" color="warn" *ngIf="currencySnapshot">Delete</button>
  <button mat-button (click)="dialogRef.close()" style="margin-left: auto">Cancel</button>
  <button mat-flat-button (click)="saveCurrency()" color="primary" style="margin-left: 8px"
          [disabled]="currencyForm.invalid || currencyForm.pristine">{{currencySnapshot ? 'Update' : 'Save'}}</button>
</div>
