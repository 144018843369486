import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private alerted = false;

  constructor(public authService: AuthService, private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getAuthStatus()) {
      request = request.clone({
          headers: request.headers
          .set('Authorization', this.authService.getToken())
      });
    }

    return next.handle(request).pipe(
      catchError( (error) => {
        console.log('error(AuthenticationInterceptor)');
        console.log(error);

        // Checking if it is an Authentication Error
        switch (error.status) {
          case 401:
          case 403:
            // alert('Session expired');
            this.router.navigate([ '/auth' ]);
            break;
        }

        return throwError(error);
      })
    );
  }
}
