import { Margin } from './margin';

export class MarginRequest {
  type: string;
  reqAdminMarginArrayList: Array<Margin>;

  constructor(type: string, reqAdminMarginArrayList: Array<Margin>) {
    this.type = type;
    this.reqAdminMarginArrayList = reqAdminMarginArrayList;
  }
}
