export class Margin {
  id: string;
  sourceCurrency: string;
  sourceCountryAlpha2: string;
  destinationCurrency: string;
  destinationCountryAlpha2: string;
  margin: number;

  constructor(sourceCurrency: string, sourceCountryAlpha2: string, destinationCurrency: string, destinationCountryAlpha2: string, margin: number) {
    this.sourceCurrency = sourceCurrency;
    this.sourceCountryAlpha2 = sourceCountryAlpha2;
    this.destinationCurrency = destinationCurrency;
    this.destinationCountryAlpha2 = destinationCountryAlpha2;
    this.margin = margin;
  }
}
