export class Currency {
  name: string;
  alpha3: string;
  decimals: number;
  enabled = false;

  constructor(name: string, alpha3: string, decimals: number, enabled?: boolean) {
    this.name = name;
    this.alpha3 = alpha3;
    this.decimals = decimals;
    this.enabled = enabled;
  }
}
