import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MarginService} from '../../services/margin.service';
import {Margin} from '../../entities/margin';
import { SnackbarService, ToastType } from 'src/app/services/snackbar.service';

export class MarginFormResult {
  action: 'save' | 'delete';
  margin: Margin;
}

@Component({
  selector: 'app-margin-edit-dialog',
  templateUrl: './margin-edit-dialog.component.html',
  styleUrls: ['./margin-edit-dialog.component.scss']
})
export class MarginEditDialogComponent implements OnInit {

  marginSnapshot: Margin;

  marginForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<MarginEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public margin: Margin,
              private marginService: MarginService) {
    if (margin) {
      this.marginSnapshot = margin;
    }

    this.marginForm = new FormGroup({
      margin: new FormControl( 0, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.marginForm != null && this.marginSnapshot != null){
      this.marginForm.patchValue(this.marginSnapshot);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveMargin() {
    this.dialogRef.close({action: 'save', margin: this.marginForm.value});
  }

  deleteMargin() {
    this.dialogRef.close({action: 'delete', margin: this.marginForm.value});
  }
}
