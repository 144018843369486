<mat-menu #appMenu="matMenu">
  <button mat-menu-item (click)="refreshMargins()" [disabled]="isBusy">
    <mat-icon>refresh</mat-icon>
    <span>Refresh</span>
  </button>
  <button mat-menu-item (click)="openBulkAddDialog()" [disabled]="isBusy"><!--uploader.click()-->
<!--    <input hidden type="file" #uploader (change)="uploadFile($event)"/>-->
    <mat-icon>publish</mat-icon>
    <span>Upload Margins</span>
  </button>
  <button mat-menu-item (click)="downloadMargins()">
    <mat-icon>get_app</mat-icon>
    <span>Download</span>
  </button>
</mat-menu>
<mat-menu #itemMenu="matMenu" (closed)="selectedMargin = undefined">
  <button mat-menu-item (click)="editSelectedCurrency()">
    <mat-icon>edit</mat-icon>
    <span>Edit</span>
  </button>
  <button mat-menu-item (click)="deleteMargin(selectedMargin)">
    <mat-icon class="warn">delete</mat-icon>
    <span class="warn">Delete</span>
  </button>
</mat-menu>
<mat-card>
  <mat-card-header>
    <mat-card-title>Margins</mat-card-title>
    <button mat-icon-button [matMenuTriggerFor]="appMenu" style="margin-left: auto">
      <mat-icon>more_vert</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="margins" class="mat-elevation-z8">

      <!-- Name Column -->
      <ng-container matColumnDef="currencyPair">
        <th mat-header-cell *matHeaderCellDef>Currency Pair</th>
        <td mat-cell *matCellDef="let element">
          <div class="currency-pair" fxLayout="row" fxLayoutAlign="flex-start center" style="padding-right: 16px">
            <b>{{element.sourceCurrency}} </b>
            <mat-icon>chevron_right</mat-icon>
            <b>{{element.destinationCurrency}}</b>
          </div>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="margin">
        <th mat-header-cell *matHeaderCellDef style="width: 100%">Margin</th>
        <td mat-cell *matCellDef="let element"><b>{{element.margin + '%'}}</b></td>
      </ng-container>

      <!-- Menu Button Column -->
      <ng-container matColumnDef="menu" stickyEnd>
        <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
        <td mat-cell *matCellDef="let element">
          <button class="item-menu-button" [ngClass]="{active: selectedMargin == element}" mat-icon-button
                  (click)="setSelectedEntry(element)" [mat-menu-trigger-for]="itemMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
  <mat-progress-bar mode="indeterminate" *ngIf="isBusy" color="accent"></mat-progress-bar>
</mat-card>
