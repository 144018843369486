import {Component, OnInit} from '@angular/core';
import {Currency} from '../../entities/currency';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  sourceCurrencyCount = 0;
  destinationCurrencyCount = 0;
  ratesCount: number;
  marginsCount: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  sourceCurrencyCountChanged(count: number) {
    this.sourceCurrencyCount = count;
  }

  destinationCurrencyCountChanged(count: number) {
    this.destinationCurrencyCount = count;
  }

  ratesCountChanged(count: number) {
    this.ratesCount = count;
  }

  marginsCountChanged(count: number) {
    this.marginsCount = count;
  }
}
