<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <span>{{(marginSnapshot ? 'Edit' : 'Add') + ' Margin'}}</span>
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="content" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="16px">
  <div class="currency-pair" fxLayout="row" fxLayoutAlign="flex-start center" style="padding: 32px 0">
    <!--            <img src="https://www.countryflags.io/{{element.sourceCountryAlpha2}}/flat/64.png">-->
    <b>{{marginSnapshot.sourceCurrency}} </b>
    <mat-icon>chevron_right</mat-icon>
    <b>{{marginSnapshot.destinationCurrency}}</b>
    <!--            <img src="https://www.countryflags.io/{{element.destinationCountryAlpha2}}/flat/64.png">-->
  </div>
  <form [formGroup]="marginForm" fxLayout="column" fxLayoutGap="16px">
    <mat-form-field appearance="fill" fxFlex="50%">
      <mat-label>Margin</mat-label>
      <input matInput placeholder="Margin" formControlName="margin">
      <mat-hint>eg. 10.5%</mat-hint>
    </mat-form-field>
  </form>
</div>
<div class="footer" fxLayout="row" fxLayoutAlign="flex-start center">
  <button mat-button (click)="deleteMargin()" color="warn" *ngIf="marginSnapshot">Delete</button>
  <button mat-button (click)="dialogRef.close()" style="margin-left: auto">Cancel</button>
  <button mat-flat-button (click)="saveMargin()" color="primary" style="margin-left: 8px"
          [disabled]="marginForm.invalid || marginForm.pristine">{{marginSnapshot ? 'Update' : 'Save'}}</button>
</div>
