<div class="wrapper" fxLayout="column" fxLayoutAlign="flex-start center">
  <div class="content" fxLayout="column" fxLayoutAlign="flex-start center" fxFlex="100%">
    <div class="header" fxLayout="row" fxLayoutAlign="flex-start center">
      <h1>&nbsp;</h1>
    </div>
    <div class="body" fxLayout="row" fxLayoutGap="8px" fxFlex="100%">
      <div class="rates-margins" fxLayout="row" fxLayoutGap="8px" fxFlex="50%">
        <div fxLayout="column" fxLayoutGap="8px" fxFlex="50%">
          <app-rates-card (countChanged)="ratesCountChanged($event)"></app-rates-card>
          <app-total-card title="Rate Count" [count]="ratesCount" color="accent"></app-total-card>
        </div>
        <div fxLayout="column" fxLayoutGap="8px" fxFlex="50%">
          <app-margins-card (countChanged)="marginsCountChanged($event)"></app-margins-card>
          <app-total-card title="Margin Count" [count]="marginsCount" color="accent"></app-total-card>
        </div>
      </div>
      <div class="currencies" fxLayout="row" fxLayoutGap="8px" fxFlex="50%">
        <div class="source-currencies" fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
          <app-currencies-list title="Source Currencies" type="source"
                               (countChanged)="sourceCurrencyCountChanged($event)"></app-currencies-list>
          <app-total-card title="Source Currency Count" [count]="sourceCurrencyCount" color="primary"></app-total-card>
        </div>
        <div class="destination-currencies" fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
          <app-currencies-list title="Destination Currencies" type="destination"
                               (countChanged)="destinationCurrencyCountChanged($event)"></app-currencies-list>
          <app-total-card title="Destination Currency Count" [count]="destinationCurrencyCount" color="primary"></app-total-card>
        </div>
      </div>
    </div>
  </div>
</div>
<!--        <mat-card class="reporting" fxLayout="column">-->
<!--          <mat-card-header>-->
<!--            <mat-card-title>Report Download</mat-card-title>-->
<!--          </mat-card-header>-->
<!--          <mat-card-content fxLayout="row" fxLayoutGap="4px">-->
<!--            <button mat-stroked-button color="primary">Mastercard Rates</button>-->
<!--            <button mat-stroked-button color="primary">Margins</button>-->
<!--            <button mat-stroked-button color="primary">Source Currencies</button>-->
<!--            <button mat-stroked-button color="primary" style="margin: auto">Destination Currencies</button>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
