import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService, ToastType } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordResetForm: FormGroup = new FormGroup({
    newPassword: new FormControl('', Validators.compose([
      Validators.minLength(6),
      Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!~*()]).*$'),
      Validators.required])),
    newPasswordConfirmation: new FormControl('', Validators.compose([
      Validators.minLength(6),
      Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!~*()]).*$'),
      Validators.required]))
  });

  showProgressBar = false;

  constructor( private toastService: SnackbarService, private authService: AuthService, private router: Router) {
    if (this.authService.getCognitoUser() == null){
      this.router.navigateByUrl('/auth/recovery');
    }
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.passwordCheck()) {
      this.showProgressBar = true;
      if (this.authService.getResetCode() == null || this.authService.getResetCode() === ''){
        this.authService.setNewPassword(this.passwordResetForm.value.newPassword, {
          onSuccess: (result) => {
            this.showProgressBar = false;
          },
          onFailure: (err) => {
            this.showProgressBar = false;
          },
        });  // Set new password for new account
      }else{
        this.authService.resetPassword(this.passwordResetForm.value.newPassword, {
          onSuccess: (result) => {
            this.showProgressBar = false;
          },
          onFailure: (err) => {
            this.showProgressBar = false;
          },
        });   // Reset forgotten password
      }
    }
  }

  passwordCheck(): boolean {
    if (this.passwordResetForm.value.newPassword !== this.passwordResetForm.value.newPasswordConfirmation) {
      this.toastService.queue(ToastType.ERROR, 'New password and repeated password do not match');
      return false;
    }
    return true;
  }
}
