import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Currency} from '../../entities/currency';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CurrencyService} from '../../services/currency.service';

export class CurrencyFormResult {
  action: 'save' | 'delete';
  currency: Currency;
}


@Component({
  selector: 'app-currency-edit-dialog',
  templateUrl: './currency-edit-dialog.component.html',
  styleUrls: ['./currency-edit-dialog.component.scss']
})
export class CurrencyEditDialogComponent implements OnInit {
  currencySnapshot: Currency;

  currencyForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<CurrencyEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public currency: Currency,
              private currencyService: CurrencyService) {
    if (currency) {
      this.currencySnapshot = currency;
    }

    this.currencyForm = new FormGroup({
      alpha3: new FormControl({value: currency ? currency.alpha3 : '', disabled: !!currency}, [Validators.required, Validators.pattern('\\b[a-z,A-Z]{3}\\b')]),
      decimals: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      name: new FormControl('', Validators.required),
      enabled: new FormControl(true)
    });
  }

  ngOnInit(): void {
    if (this.currencyForm != null && this.currencySnapshot != null){
      this.currencyForm.patchValue(this.currencySnapshot);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveCurrency() {
    this.dialogRef.close({
      action: 'save',
      currency: {
        ...this.currencyForm.value,
        alpha3: (this.currencySnapshot ? this.currencySnapshot.alpha3 : this.currencyForm.value.alpha3).toUpperCase()
      }
    });
  }

  deleteCurrency() {
    this.dialogRef.close({action: 'delete', currency: this.currencyForm.value});
  }
}
