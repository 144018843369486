import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Rate} from '../entities/rate';
import * as FileSaver from 'file-saver';
import {ApiService} from './apiService';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RateService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  getRates(): Observable<Rate[]> {
    return this.http.get<Rate[]>(this.baseUrl + 'rates');
  }

  downloadReport() {
    this.http.get(this.baseUrl + 'rates/report', {responseType: 'text'}).subscribe(response => {
      const blob = new Blob([response], {type: 'text/csv;charset=utf-8'});
      FileSaver.saveAs(blob, `tvx-vbmc-rates-snapshot-${Date.now().toString()}.csv`);
    });
  }
}
