import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-recovery-code',
  templateUrl: './recovery-challenge.component.html',
  styleUrls: ['./recovery-challenge.component.scss']
})
export class RecoveryChallengeComponent implements OnInit {

  challengeForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.email),
    code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
  });

  showProgressBar = false;

  constructor(public authService: AuthService, private router: Router) {

  }

  ngOnInit() {
    if (this.authService.getCognitoUser() != null){
      this.challengeForm.controls.username.disable();
      this.challengeForm.controls.username.setValue(this.authService.getCognitoUser().getUsername());
    }else{
      this.router.navigateByUrl('/auth/recovery');
    }
  }

  onSubmit() {
    this.authService.setResetCode(this.challengeForm.value.code);
    this.router.navigateByUrl('/auth/reset');
  }
}
