<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
  <!--  <button type="button" mat-icon-button (click)="onMenuClick()">-->
  <!--    <mat-icon>menu</mat-icon>-->
  <!--  </button>-->
  <img class="logo" src="/assets/img/travelex.png">
  <div class="right">
    <div class="user-button" [matMenuTriggerFor]="menu" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
      <div class="details">
        <span style="font-size: 14px; font-weight: bold">{{email}}</span>
      </div>
      <div class="profile-picture"></div>
      <mat-menu #menu>
        <button mat-menu-item (click)="signout()">Sign Out</button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
