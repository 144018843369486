import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  showProgressBar = false;

  constructor(private router: Router, public authService: AuthService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    this.showProgressBar = true;
    this.authService.signin(this.signinForm.value.username, this.signinForm.value.password, {
      onSuccess: (result) => {
        this.showProgressBar = false;
      },
      onFailure: (err) => {
        this.showProgressBar = false;
      },
    });
  }
}
