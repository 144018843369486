import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RateService} from '../../services/rate.service';
import {Rate} from '../../entities/rate';
import {Margin} from '../../entities/margin';

@Component({
  selector: 'app-rates-card',
  templateUrl: './rates-card.component.html',
  styleUrls: ['./rates-card.component.scss']
})
export class RatesCardComponent implements OnInit {

  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  displayedColumns: string[] = [/*'country', */'destinationCurrency', 'rate'];
  rates: Rate[] = [];
  isBusy: any = false;

  constructor(private rateService: RateService) {
  }

  ngOnInit(): void {
    this.refreshRates();
  }

  public refreshRates() {
    if (!this.isBusy) {
      this.isBusy = true;
      this.rateService.getRates().subscribe((rates: Rate[]) => {
        this.isBusy = false;
        this.rates = rates.sort((a: Rate, b: Rate) => {
          if (a.destinationCurrency > b.destinationCurrency) {
            return 1;
          } else if (a.destinationCurrency < b.destinationCurrency) {
            return -1;
          } else if (a.destinationCurrency === b.destinationCurrency) {
            return 0;
          }
        });
        this.countChanged.emit(rates.length);
      }, error => {
        this.isBusy = false;
      });
    }
  }

  downloadReport() {
    this.rateService.downloadReport();
  }
}
