import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Margin} from '../../entities/margin';
import {MarginService} from '../../services/margin.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MarginEditDialogComponent, MarginFormResult} from '../margin-edit-dialog/margin-edit-dialog.component';
import {BulkAddMarginDialogComponent} from '../bulk-add-margin-dialog/bulk-add-margin-dialog.component';
import { SnackbarService, ToastType } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-margins-card',
  templateUrl: './margins-card.component.html',
  styleUrls: ['./margins-card.component.scss']
})
export class MarginsCardComponent implements OnInit {

  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  displayedColumns: string[] = ['currencyPair', 'margin', 'menu'];
  margins: Margin[] = [];
  isBusy: any = false;
  selectedMargin: Margin;

  constructor(private marginService: MarginService,
              public dialog: MatDialog,
              private snackbarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.loadMargins();
  }

  private loadMargins() {
    this.isBusy = true;
    this.marginService.getMargins().subscribe((margins: Margin[]) => {
      this.isBusy = false;
      this.margins = margins?.sort((a: Margin, b: Margin) => {
        if (a.sourceCurrency > b.sourceCurrency) {
          return 1;
        } else if (a.sourceCurrency < b.sourceCurrency) {
          return -1;
        } else if (a.sourceCurrency === b.sourceCurrency) {
          return 0;
        }
      });
      this.countChanged.emit(margins.length);
    }, error => {
      this.isBusy = false;
    });
  }

  refreshMargins() {
    this.loadMargins();
  }

  downloadMargins() {
    this.marginService.downloadReport();
  }

  setSelectedEntry(margin: Margin) {
    this.selectedMargin = margin;
  }

  editSelectedCurrency() {
    this.openEditDialog(this.selectedMargin);
  }

  openEditDialog(margin?: Margin): void {
    let dialogRef: MatDialogRef<MarginEditDialogComponent, any>;
    if (margin) {
      dialogRef = this.dialog.open(MarginEditDialogComponent, {
        width: '480px',
        data: margin
      });
    } else {
      dialogRef = this.dialog.open(MarginEditDialogComponent, {
        width: '480px'
      });
    }

    dialogRef.afterClosed().subscribe((formResult: MarginFormResult) => {
      if (formResult) {
        if (formResult.action === 'save') {
          margin.margin = formResult.margin.margin;
          this.marginService.saveMargin(margin).subscribe(response => {
              this.snackbarService.queue(ToastType.SUCCESS, 'Margin updated successfully.');
              this.refreshMargins();
            }, error => {
              this.snackbarService.queue(ToastType.ERROR, 'An error occured while updating the margin.');
              this.refreshMargins();
            });
        } else if (formResult.action === 'delete') {
          this.deleteMargin(margin);
        }
      }
    });
  }

  openBulkAddDialog(): void {
    let dialogRef: MatDialogRef<BulkAddMarginDialogComponent, any>;

    dialogRef = this.dialog.open(BulkAddMarginDialogComponent, {
      width: '700px',
      // height: '500px',
      disableClose: true,
      data: this.margins
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshMargins();
    });
  }

  deleteMargin(selectedMargin: Margin) {
    this.marginService.deleteMargin(selectedMargin.id).subscribe(response => {
      this.snackbarService.queue(ToastType.SUCCESS, 'Margin deleted successfully.');
      this.refreshMargins();
    }, error => {
      this.snackbarService.queue(ToastType.ERROR, 'An error occured while deleting the margin.');
      this.refreshMargins();
    });
  }
}
