import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from './components/auth/auth.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuthGuard} from './guards/auth.guard';
import {SigninComponent} from './components/signin/signin.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {RecoveryComponent} from './components/recovery/recovery.component';
import {RecoveryChallengeComponent} from './components/recovery-code/recovery-challenge.component';


const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {path: 'signin', component: SigninComponent},
      // {path: 'signup', component: SignupComponent},
      {path: 'reset', component: ResetPasswordComponent},
      {path: '', redirectTo: 'signin', pathMatch: 'full'},
      {path: 'recovery', component: RecoveryComponent},
      {path: 'recovery-code', component: RecoveryChallengeComponent},
      {path: '**', redirectTo: 'signin'},
    ]
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
