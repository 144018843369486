import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CurrencyService} from '../../services/currency.service';
import {Currency} from '../../entities/currency';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CurrencyEditDialogComponent, CurrencyFormResult} from '../currency-edit-dialog/currency-edit-dialog.component';
import {Rate} from '../../entities/rate';
import { SnackbarService, ToastType } from 'src/app/services/snackbar.service';

const dummySourceData: Currency[] = [];

const dummyDestinationData: Currency[] = [];

@Component({
  selector: 'app-currencies-list',
  templateUrl: './currencies-list.component.html',
  styleUrls: ['./currencies-list.component.scss']
})
export class CurrenciesListComponent implements OnInit {

  @Input() title;
  @Input() type: 'source' | 'destination';

  @Output() countChanged: EventEmitter<number> = new EventEmitter();

  displayedColumns: string[] = ['alpha3', 'name', 'menu'];
  currencies = this.type === 'source' ? dummySourceData : dummyDestinationData;
  isBusy = false;
  selectedCurrency: Currency;


  constructor(private currenciesService: CurrencyService,
              public dialog: MatDialog,
              private snackbarService: SnackbarService) {
  }

  ngOnInit(): void {
    this.refreshCurrencies();
  }

  public refreshCurrencies() {
    if (!this.isBusy) {
      this.isBusy = true;
      this.currenciesService.getCurrencies(this.type).subscribe((currencies: Currency[]) => {
        this.isBusy = false;
        this.currencies = currencies.sort((a: Currency, b: Currency) => {
          if (a.alpha3 > b.alpha3) {
            return 1;
          } else if (a.alpha3 < b.alpha3) {
            return -1;
          } else if (a.alpha3 === b.alpha3) {
            return 0;
          }
        });
        this.countChanged.emit(currencies.length);
      }, error => {
        this.isBusy = false;
      });
    }
  }

  downloadReport() {
    this.currenciesService.downloadReport(this.type);
  }

  setSelectedEntry(currency: Currency) {
    this.selectedCurrency = currency;
  }

  editSelectedCurrency() {
    this.openDialog(this.selectedCurrency);
  }

  openDialog(currency?: Currency): void {
    let dialogRef: MatDialogRef<CurrencyEditDialogComponent, any>;
    if (currency) {
      dialogRef = this.dialog.open(CurrencyEditDialogComponent, {
        width: '480px',
        data: currency
      });
    } else {
      dialogRef = this.dialog.open(CurrencyEditDialogComponent, {
        width: '480px'
      });
    }

    dialogRef.afterClosed().subscribe((currencyFormResult: CurrencyFormResult) => {
      if (currencyFormResult) {
        if (currencyFormResult.action === 'save') {
          this.currenciesService.saveCurrency(this.type, currencyFormResult.currency).subscribe(response => {
            this.snackbarService.queue(ToastType.SUCCESS, 'Currency saved successfully.');
            this.refreshCurrencies();
          }, error => {
            this.snackbarService.queue(ToastType.ERROR, 'An error occured while saving the currency.');
            this.refreshCurrencies();
          });
        } else if (currencyFormResult.action === 'delete') {
          this.deleteSelectedCurrency(currencyFormResult.currency.alpha3);
        }
      }
    });
  }

  addCurrency() {
    this.openDialog();
  }

  deleteSelectedCurrency(alpha3: string) {
    this.currenciesService.deleteCurrency(this.type, alpha3).subscribe(response => {
      this.snackbarService.queue(ToastType.SUCCESS, 'Currency deleted.');
      this.refreshCurrencies();
    }, error => {
      this.snackbarService.queue(ToastType.ERROR, 'An error occured while deleting the currency.');
      this.refreshCurrencies();
    });
  }
}
