<h3>Password Recovery</h3>
<form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput placeholder="Email" formControlName="username">
  </mat-form-field>
  <div class="footer" fxLayoutAlign="space-between center">
    <a [routerLink]="['/auth/signin']">Sign In</a>
    <button type="submit" mat-raised-button color="primary" [disabled]="recoveryForm.invalid || showProgressBar">Send Recovery Email</button>
  </div>
</form>
<div class="progressbar" *ngIf="showProgressBar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
