import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthService, User} from '../../services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Output() menuClick: EventEmitter<any> = new EventEmitter<any>();
  email: string;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.email = this.authService.getUserName();
  }

  onMenuClick() {
    this.menuClick.emit();
  }

  signout() {
    this.authService.signout();
  }
}
