<div class="wrapper" fxLayout="column">
  <app-toolbar (menuClick)="drawer.toggle()"></app-toolbar>
  <mat-drawer-container class="container" autosize>

    <mat-drawer #drawer class="sidenav" mode="side">
      <app-sidenav-content></app-sidenav-content>
    </mat-drawer>

    <div class="content">
      <app-home></app-home>
    </div>

  </mat-drawer-container>
</div>
