import {Component, HostBinding, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MarginService} from '../../services/margin.service';
import {Margin} from '../../entities/margin';
import * as CSVFileValidator from 'csv-file-validator';
import {map} from 'rxjs/operators';
import {SnackbarService, ToastType} from 'src/app/services/snackbar.service';
import {MarginRequest} from 'src/app/entities/margin-request';

class DiffMargin extends Margin {
  type: 'new' | 'update' | 'delete' | 'unchanged';

  constructor(type: 'new' | 'update' | 'delete' | 'unchanged', margin: Margin) {
    super(margin.sourceCurrency,
      margin.sourceCountryAlpha2,
      margin.destinationCurrency,
      margin.destinationCountryAlpha2,
      margin.margin);
    this.type = type;
  }
}

@Component({
  selector: 'app-margin-edit-dialog',
  templateUrl: './bulk-add-margin-dialog.component.html',
  styleUrls: ['./bulk-add-margin-dialog.component.scss']
})
export class BulkAddMarginDialogComponent implements OnInit {

  invalid = true;
  file;

  existingMargins: Margin[] = [];
  marginsInFile: Margin[];
  duplicateMargins: Margin[];
  diffMargins: DiffMargin[];

  errors: string[];

  unchangedCount = 0;
  addedCount = 0;
  updatedCount = 0;
  deletedCount = 0;
  merge = false;
  duplicatesActive = false;
  isBusy = false;
  errorsActive: boolean;

  fileOver = false;

  marginsToUpdate: Margin[] = [];
  marginsToDelete: Margin[] = [];

  totalToUpdate = 0;
  successfulUpdates = 0;
  failedUpdates = 0;

  cancelled = false;

  constructor(public dialogRef: MatDialogRef<BulkAddMarginDialogComponent>,
              private marginService: MarginService,
              private snackbarService: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public margins: Margin[]) {
    this.existingMargins = margins;
  }

  ngOnInit(): void {
    // this.marginService.getMargins().subscribe(margins => {
    //   this.existingMargins = margins.sort(this.reorderMargins);
    // });
  }

  reset() {
    this.file = undefined;
    this.isBusy = false;
    this.marginsInFile = undefined;
    this.diffMargins = undefined;
    this.errors = undefined;
    this.unchangedCount = 0;
    this.addedCount = 0;
    this.updatedCount = 0;
    this.deletedCount = 0;
    this.duplicateMargins = [];
  }

  uploadFile($event: any) {
    this.reset();

    this.file = $event.target.files[0];

    if (this.file != null) {
      const fileName = this.file.name;

      const config = {
        headers: [
          {
            name: 'sourceCurrency',
            inputName: 'sourceCurrency',
            required: true
          },
          {
            name: 'destinationCurrency',
            inputName: 'destinationCurrency',
            required: true
          },
          {
            name: 'margin',
            inputName: 'margin',
            required: true
          },
        ]
      };

      this.marginsToUpdate.length = 0;
      this.marginsToDelete.length = 0;

      CSVFileValidator(this.file, config)
        .then(csvData => {

          console.log(csvData);

          this.errors = undefined;
          this.marginsInFile = [];

          if (!(csvData.inValidMessages.length > 0) && csvData.data.length > 0) {
            this.marginsInFile = csvData.data.sort(this.reorderMargins);

            this.invalid = false;
          }

          if (csvData.inValidMessages.length > 0) {
            this.errors = csvData.inValidMessages;
          }

          this.generateDiff();
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  private generateDiff() {
    // Generate map of existing margins for diff reference
    const existingMarginsMap = new Map<string, Margin>();

    this.existingMargins.forEach(m => {
      const key = `${m.sourceCurrency}|${m.destinationCurrency}`;
      existingMarginsMap.set(key, m);
    });

    // Clear duplicate entries
    this.duplicateMargins.length = 0;

    // Generate map of new margins
    const marginDiffMap = new Map<string, DiffMargin>();

    this.marginsInFile.forEach(fileMargin => {
      const key = `${fileMargin.sourceCurrency}|${fileMargin.destinationCurrency}`;
      if (!marginDiffMap.has(key)) {
        let diffAction: 'new' | 'update' | 'delete' | 'unchanged' = 'unchanged';

        if (existingMarginsMap.has(key)) {
          if (+existingMarginsMap.get(key).margin !== +fileMargin.margin) {
            diffAction = 'update';
            this.updatedCount = this.updatedCount + 1;
            this.marginsToUpdate.push(fileMargin);
          } else {
            this.unchangedCount = this.unchangedCount + 1;
          }
        } else {
          diffAction = 'new';
          this.addedCount = this.addedCount + 1;
          this.marginsToUpdate.push(fileMargin);
        }

        marginDiffMap.set(key, new DiffMargin(diffAction, fileMargin));
      } else {
        this.duplicateMargins.push(fileMargin);
      }
    });

    existingMarginsMap.forEach((existingMargin, key) => {
      if (!marginDiffMap.has(key)) {
        marginDiffMap.set(key, new DiffMargin('delete', existingMargin));
        this.deletedCount = this.deletedCount + 1;
        this.marginsToDelete.push(existingMargin);
      }
    });

    // Pull margins into list to display in left pane
    const newDiffMargins = [];

    marginDiffMap.forEach((m, k) => {
      newDiffMargins.push(m);
    });

    this.diffMargins = newDiffMargins.sort(this.reorderMargins);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveMargins() {
    this.isBusy = true;

    const entriesPerPart = 200;

    this.totalToUpdate = this.marginsToDelete.length + this.marginsToUpdate.length;
    this.successfulUpdates = 0;
    this.failedUpdates = 0;

    if (this.merge === false) {
      this.callDeleteApi(entriesPerPart, 0, {
        onSuccess: () => {
          this.updateMargins(entriesPerPart);
        }
      });
    } else {
      this.updateMargins(entriesPerPart);
    }
  }

  updateMargins(entriesPerPart: number){
    this.marginsToUpdate.forEach(margin => {
      if (margin.id == null || margin.id === '') {
        margin.id = margin.sourceCurrency + '|' + margin.destinationCurrency;
      }
    });

    if (this.cancelled === false) {
      this.callUpdateApi(entriesPerPart, 0);
    } else {
      this.saveCancelled();
    }
  }

  callDeleteApi(entriesPerPart: number, successfulApiCalls: number, callback: any) {
    if (this.marginsToDelete.length > 0) {
      const marginsPart = this.marginsToDelete.splice(0, entriesPerPart);

      if (marginsPart.length > 0) {
        const marginsDeleteRequest = new MarginRequest('delete', marginsPart);

        this.marginService.uploadMargins(marginsDeleteRequest).subscribe((deleteResult) => {
          successfulApiCalls++;

          this.successfulUpdates += marginsPart.length;

          if (this.cancelled === false) {
            this.callDeleteApi(entriesPerPart, successfulApiCalls, callback);
          } else {
            this.saveCancelled();
          }
        }, error => {
          this.saveFailed(error, successfulApiCalls);
        });
      }
    } else {
      callback.onSuccess();
    }
  }

  callUpdateApi(entriesPerPart: number, successfulApiCalls: number) {
    if (this.marginsToUpdate.length > 0) {
      const marginsPart = this.marginsToUpdate.splice(0, entriesPerPart);

      if (marginsPart.length > 0) {
        const marginsUpdateRequest = new MarginRequest('update', marginsPart);

        this.marginService.uploadMargins(marginsUpdateRequest).subscribe((updateResult) => {
          successfulApiCalls++;

          this.successfulUpdates += marginsPart.length;

          if (this.cancelled === false) {
            this.callUpdateApi(entriesPerPart, successfulApiCalls);
          } else {
            this.saveCancelled();
          }
        }, error => {
          this.saveFailed(error, successfulApiCalls);
        });
      }
    } else {
      this.saveComplete();
    }
  }

  saveComplete() {
    this.isBusy = false;
    this.dialogRef.close();
    this.snackbarService.queue(ToastType.SUCCESS, 'Margin update successful.');
  }

  saveFailed(errors: any, successes: any) {
    console.log(successes + ' successful update calls');
    console.log(errors);
    this.isBusy = false;

    this.failedUpdates = this.totalToUpdate - this.successfulUpdates;

    if (successes > 0) {
      this.snackbarService.queue(ToastType.ERROR, 'Margin update failed. A partial update has been done. Please verify that the margins are correct.');
    } else {
      this.snackbarService.queue(ToastType.ERROR, 'Margin update failed.');
    }
  }

  saveCancelled() {
    this.isBusy = false;

    this.failedUpdates = this.totalToUpdate - this.successfulUpdates;

    this.snackbarService.queue(ToastType.ERROR, 'Margin update cancelled.');

    this.dialogRef.close();
  }

  cancel() {
    this.snackbarService.queue(ToastType.ERROR, 'Cancelling margin update, please wait...');
    this.cancelled = true;
    if (this.isBusy === false) {
      this.saveCancelled();
    }
  }

  private reorderMargins(a: Margin, b: Margin) {
    if (a.sourceCurrency > b.sourceCurrency) {
      return 1;
    } else if (a.sourceCurrency < b.sourceCurrency) {
      return -1;
    } else if (a.sourceCurrency === b.sourceCurrency) {
      return 0;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;

    const inputFiles = evt.dataTransfer.files;

    if (inputFiles.length > 0) {
      if (inputFiles.length > 1) {
        this.snackbarService.queue(ToastType.ERROR, 'Only 1 file can be uploaded at a time.');
      } else {
        const event = {
          target: {
            files: inputFiles
          }
        };

        this.uploadFile(event);
      }
    }
  }
}
